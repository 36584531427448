@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');

body {
	min-height: 100vh;
	height: 100%;
	font-family: 'Poppins', sans-serif;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	overflow-x: hidden;
	background: #f9f9f9;

	&.no-scroll {
		overflow: hidden;
		max-height: 100vh;
	}

	ul, ol, p {
		margin: 0;
		padding: 0;
	}

	a {
		text-decoration: none !important;
		color: @color1;

		&:hover {
			color: darken(@color1, 10%);
		}
	}

	p {
		color: #666;
		font-size: 13px;
		line-height: 22px;
	}

	ul, ol {
		color: #666;
		font-size: 13px;
		line-height: 22px;
		padding-left: 18px;
	}

	.list-unstyled {
		padding: 0;
	}

	.btn {
		margin: 0;
		border-radius: 40px;
		font-size: 11px;
		text-transform: uppercase;
		padding: 10px 30px;
		color: #fff !important;
		transition: 0.3s;

		&.btn-sm {
			padding: 5px 22px;
			font-size: 10px;
		}

		&.btn-100 {
			width: 100%;
		}

		&.loading {
			position: relative;
			pointer-events: none;

			&:before {
				content: '';
				position: absolute;
				top: -10%;
				left: -5%;
				width: 110%;
				height: 120%;
				background: #fff;
				opacity: 0.85;
				filter: blur(1px);
			}

			&:after {
				content: '';
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				background: url(../img/icons/loading.svg) center;
				background-size: cover;
				height: 18px;
				width: 18px;
				animation: loader-rotate cubic-bezier(0.455, 0.03, 0.515, 0.955) 4s infinite;
			}

			@keyframes loader-rotate {
				0% {
					transform: translate(-50%, -50%) rotate(0deg);
				}
				100% {
					transform: translate(-50%, -50%) rotate(360deg);
				}
			}
		}
	}

	.m-t-5 { margin-top: 5px; }
	.m-t-10 { margin-top: 10px; }
	.m-t-15 { margin-top: 15px; }
	.m-t-20 { margin-top: 20px; }
	.m-t-25 { margin-top: 25px; }
	.m-t-30 { margin-top: 30px; }
	.m-b-5 { margin-bottom: 5px; }
	.m-b-10 { margin-bottom: 10px; }
	.m-b-15 { margin-bottom: 15px; }
	.m-b-20 { margin-bottom: 20px; }
	.m-b-25 { margin-bottom: 25px; }
	.m-b-30 { margin-bottom: 30px; }
	.p-t { padding-top: 15px; }
	.p-t-5 { padding-top: 5px; }
	.p-t-10 { padding-top: 10px; }
	.p-t-15 { padding-top: 15px; }
	.p-t-20 { padding-top: 20px; }
	.p-t-25 { padding-top: 25px; }
	.p-t-30 { padding-top: 30px; }
	.p-b-5 { padding-bottom: 5px; }
	.p-b-10 { padding-bottom: 10px; }
	.p-b-15 { padding-bottom: 15px; }
	.p-b-20 { padding-bottom: 20px; }
	.p-b-25 { padding-bottom: 25px; }
	.p-b-30 { padding-bottom: 30px; }
}

@color1: #2bbbad;
@sidebar-width: 260px;

.body-payment-alert {
	position: fixed;
	top: 0;
	left: 0;
	min-height: 100vh;
	width: 100vw;
	padding: 30px;
	background: #fff;
	z-index: 1001;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	text-align: center;

	.alert-logo {
		height: 20px;
		margin-bottom: 20px;
	}

	h1 {
		font-weight: 500;
	}

	p {
		max-width: 600px;
	}

	ul {
		margin-top: 30px;

		li {
			a {
				display: flex;
				align-items: center;
				justify-content: center;

				svg {
					min-width: 30px;
					max-width: 30px;
					margin-right: 10px;
					fill: @color1;
				}

				span {
					font-size: 17px;
					font-weight: 800;
				}
			}

			& + li {
				margin-top: 15px;
			}
		}
	}
}

.demo-label {
	position: fixed;
	z-index: 1002;
	left: 50%;
	bottom: 20px;
	transform: translateX(-50%);
	background: rgba(0, 0, 0, 0.7);
	padding: 10px 20px;
	transition: all 0.4s;
	border-radius: 2px;
	text-align: center;
	opacity: 0.5;

	.label-heading {
		color: #fff;
		font-size: 12px;
		letter-spacing: 1px;
	}

	.btn {
		margin-top: 10px;
	}

	&:hover {
		opacity: 1;
	}
}

section.logowanie-section-content {
	.content-flexbox {
		min-height: 100vh;
		display: flex;

		.flexbox-service {
			width: 40%;
			padding: 100px 50px;
			text-align: center;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			position: relative;

			.logo {
				letter-spacing: 1px;
				text-align: center;
				display: flex;
				align-items: center;
				font-size: 28px;
				font-weight: 700;
				color: #555;

				span {
					color: @color1;
					font-size: 35px;
					transform: translateY(12px);
				}

				img {
					height: 40px;
				}
			}

			.form-service {
				margin-top: 20px !important;
			}

			.btn {
				margin-top: 60px;
				width: 100%;
			}

			.forgot {
				margin-top: 30px;
				display: block;
				font-size: 11px;
				color: @color1;
				cursor: pointer;
			}

			.buttons-wrapper {
				margin-top: 50px;
				display: flex;
				justify-content: center;

				.btn {
					margin: 5px;
				}
			}
		}

		.flexbox-cta {
			width: 60%;
			overflow: hidden;

			iframe {
				border: none;
				width: 100%;
				height: 100%;
			}
		}
	}
}

.heading-language {
	display: flex;
	align-items: center;

	.name {
		font-size: 13px;
		text-transform: uppercase;
		color: #444;
	}

	.language {
		margin-left: 15px;
		padding: 3px 7px;
		background: #fff;
		border: 0.5px solid #ccc;
		border-radius: 3px;
		display: flex;
		align-items: center;

		img {
			height: 15px;
			min-width: 15px;
			max-width: 15px;
			margin-right: 8px;
		}

		span {
			font-size: 13px;
			text-transform: uppercase;
			color: #444;
		}
	}
}

.heading-language + h1 {
	margin-top: 8px !important;
}

h1 {
	font-size: 28px;
	font-weight: 400;
	color: #444;
	margin: 20px 0;
}

h2 {
	font-size: 23px;
	font-weight: 400;
	color: #444;
	margin: 20px 0;
}

h3 {
	font-size: 18px;
	font-weight: 400;
	color: #444;
	margin: 20px 0;
}

.body-wrapper {
	display: flex;
	transition: all .7s ease;

	.inner-wrapper {
		padding-top: 65px;
		padding-bottom: 57px;
		transition: all .7s ease;
		position: relative;
		min-width: calc(~'100% - 260px');
		max-width: calc(~'100% - 260px');

		.inner-heading {
			display: flex;
			justify-content: space-between;
			align-items: center;
			flex-wrap: wrap;
		}

		.basic-box {
			padding: 0 20px 20px 20px;
			background: #fff;
			position: relative;
			border: 1px solid #e7e7e7;

			.box-heading {
				margin-top: 0;
				display: flex;
				justify-content: space-between;
				align-items: center;

				h1, h2, h3 {
					padding-right: 30px;
				}

				.dropdown {
					margin-top: 20px;

					.dropdown-toggle {
						padding: 0;
						border: none;
						background: none;
						cursor: pointer;
						outline: none;

						img {
							width: 15px;
							transform: rotate(90deg);
						}
					}
				}

				&.collapse-button {
					pointer-events: none;

					.dropdown {
						display: none;
					}

					&.active {
						pointer-events: auto;

						.dropdown {
							display: block;
						}
					}
				}
			}

			> * {
				&:not(.nav-pills) {
					margin-top: 20px;
				}
			}

			h1, h2, h3 {
				margin: 20px 0 0 0;
			}

			.collapse {
				margin-top: 0;
			}

			.box-loader {
				margin-top: 0;
				position: absolute;
				top: 0;
				left: 0;
				height: 100%;
				width: 100%;
				background: rgba(255, 255, 255, 0.8);
				display: flex;
				align-items: center;
				justify-content: center;
			}
		}

		.welcome-box {
			padding: 0 20px 20px 20px;
			background: #fff;
			position: relative;
			border: 1px solid #e7e7e7;

			h2 {
				text-align: center;
				font-weight: 600;
				font-size: 36px;
				color: @color1;
				margin-bottom: 0;
			}

			h3 {
				margin-top: 5px !important;
				text-align: center;
				text-transform: uppercase;
				font-size: 13.8px;
			}

			.box-steps {
				display: flex;
				justify-content: space-between;
				flex-wrap: wrap;

				.step-service {
					display: block;
					width: 32%;
					margin-top: 2%;
					padding: 15px;
					background: #f9f9f9;
					text-align: center;

					.service-number {
						font-size: 50px;
						color: #ddd;
						font-weight: 600;
					}

					.service-name {
						text-transform: uppercase;
						font-size: 13px;
						font-weight: 800;
					}

					.service-description {
						font-size: 13px;
						color: #000;
						margin-top: 10px;
					}
				}
			}
		}

		.container-fluid, .container {
			> * {
				margin-top: 20px;
			}
		}

		&.active {
			padding-left: 0;
			margin-left: 0;
			min-width: 100%;
			max-width: 100%;
		}
	}
}

.nav-left {
	.button {
		cursor: pointer;
		width: auto;
		margin: 0 auto;
		transition: all .7s ease;

		.bar {
			display: block;
			height: 2px;
			width: 20px;
			border-radius: 20%;
			background-color: #555;
			margin-bottom: 3px;
			transition: all .7s ease;

			&:last-child {
				margin-bottom: 0;
			}
		}
	}
}

.header-sidebar {
	position: fixed;
	z-index: 334;
	top: 0;
	left: -@sidebar-width;
	min-height: 100vh;
	min-width: @sidebar-width;
	max-width: @sidebar-width;
	background: #fff;
	border-right: 1px solid #e7e7e7;
	transition: all .7s;

	.nav-left-sm {
		display: none;
		position: absolute;
		top: 7px;
		padding: 15px;
		right: 0;
		z-index: 555;
		cursor: pointer;

		.button {
			display: inline-block;

			.bar {
				&.top {
					transform: translateY(5px) rotateZ(45deg);
				}

				&.middle {
					width: 0;
				}

				&.bottom {
					transform: translateY(-5px) rotateZ(-45deg);
				}
			}
		}
	}

	.logo {
		position: fixed;
		z-index: 444;
		left: -@sidebar-width;
		top: 0;
		width: @sidebar-width;
		display: flex;
		align-items: center;
		justify-content: center;
		letter-spacing: 1px;
		text-align: center;
		font-weight: 700;
		height: 65px;
		padding: 0 15px;
		background: #fff;
		transition: all .7s;
		color: #555;
		border-right: 1px solid #e7e7e7;
		border-bottom: 1px solid #e7e7e7;

		span {
			color: @color1;
			font-size: 25px;
			transform: translateY(6px);
		}

		img {
			height: 30px;
		}
	}

	.sidebar-navigation {
		margin-top: 65px;
		padding-top: 30px;

		ul {
			li {
				position: relative;
				transition: all .7s;

				a {
					padding: 15px 35px 15px 25px;
					font-size: 14px;
					color: #444;
					opacity: 0.9;
					width: 100%;
					text-decoration: none;
					display: flex;
					align-items: center;
					position: relative;
					font-weight: 500;
					z-index: 111;
					transition: all .7s;

					img {
						width: 20px;
						margin-right: 20px;
					}

					&:hover {
						color: #000;
					}
				}

				.premium, .pro {
					position: relative;

					&:after {
						padding: 0px 5px;
						border-radius: 30px;
						position: absolute;
						top: 50%;
						right: 10px;
						color: #fff;
						font-weight: 600;
						line-height: 15px;
						font-size: 10px;
						letter-spacing: -0.5px;
						text-transform: uppercase;
						transform: translateY(-50%);
					}
				}

				.premium {
					&:after {
						content: 'Premium';
						background: #c31432;
					}
				}

				.pro {
					&:after {
						content: 'Pro';
						background: #78319d;
					}
				}

				&.has-child {
					display: flex;
					align-items: center;

					&.active {
						&:after {
							transform: rotate(90deg);
						}
					}

					&:after {
						content: '';
						width: 10px;
						height: 10px;
						display: block;
						position: absolute;
						right: 20px;
						background: url('../img/icons/nav/chevron.svg') center;
						background-size: cover;
						transition: all .7s;
					}
				}

				&.child {
					margin-top: 0;

					ul {
						li {
							a {
								padding: 6px 65px;
								display: block;
								font-size: 12px;
								color: #555;
							}
						}
					}
				}

				&:last-child {
					margin-bottom: 0;
				}
			}
		}
	}

	&.active {
		left: 0;
		position: relative;

		.logo {
			left: 0;
		}
	}
}

nav.section-header {
	height: 65px;
	background: #fff;
	position: fixed;
	z-index: 333;
	padding-left: @sidebar-width;
	border-bottom: 1px solid #e7e7e7;
	top: 0;
	width: 100%;
	transition: all .7s;

	&.active {
		padding-left: 0px;
		width: 100%;
	}

	.container-fluid {
		height: 100%;
	}

	.header-flexbox {
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: space-between;

		.flexbox-inner-wrapper {
			display: flex;
			align-items: center;
			justify-content: space-between;

			>* {
				margin-right: 30px;

				&:last-child {
					margin-right: 0;
				}
			}
		}
	}

	.nav-back {
		padding: 5px;
		margin-left: -5px;

		img {
			height: 15px;
		}
	}

	.header-icon {
		position: relative;
		padding: 0;
		background: none;
		border: none;
		cursor: pointer;
		outline: none;

		img {
			height: 20px;
		}

		.badge {
			position: absolute;
			top: -8px;
			right: -8px;
			height: 19px;
			width: 19px;
			border-radius: 50%;
			display: flex;
			align-items: center;
			justify-content: center;
			text-align: center;
			background: #cd270c;
			z-index: 111;
			font-size: 9px;
			font-weight: 300;
			color: #fff;
		}
	}

	.header-statement {
		font-size: 12px;
		color: #444;

		span {
			border-radius: 30px;
			padding: 0 5px;
			color: #fff;
			font-weight: 600;
			line-height: 15px;
			font-size: 10px;
			display: inline-block;

			&.green {
				background: olivedrab;
			}

			&.red {
				background: #c71a1a;
			}
		}
	}

	.dropdown {
		.btn-avatar {
			padding: 0;
			border: none;
			background: none;
			outline: none;
			display: flex;
			align-items: center;
			justify-content: center;
			cursor: pointer;

			.avatar-img {
				height: 40px;
				width: 40px;
				border-radius: 50%;
				overflow: hidden;

				img {
					width: 100%;
				}
			}

			.avatar-name {
				margin-left: 15px;
				font-size: 12px;
				color: #444;
			}

			&:after {
				content: none;
			}
		}

		.btn-language {
			padding: 3px 7px 4px 7px;
			border-radius: 4px;
			border: 0.5px solid #ccc;
			background: none;
			outline: none;
			display: flex;
			align-items: center;
			justify-content: center;
			cursor: pointer;

			.language-img {
				height: 20px;
				min-width: 20px;
				max-width: 20px;
				border-radius: 50%;
				overflow: hidden;

				img {
					width: 100%;
				}
			}

			.language-name {
				margin-left: 10px;
				font-size: 14px;
				color: #444;
			}
		}

		.dropdown-menu {
			.language-item {
				display: flex;
				align-items: center;
				justify-content: flex-start;
				padding-left: 10px;

				img {
					height: 18px;
					min-width: 18px;
					max-width: 18px;
					margin-right: 8px;
				}
			}
		}
	}

	.search-engine {
		display: flex;
		align-items: center;

		input {
			padding: 5px 15px;
			margin: 0;
			border-radius: 30px 0 0 30px;
			border: 1px solid @color1;
			border-right: none;
			height: 20px;
			font-size: 12px;
			width: 160px;
			transition: .7s;

			&:focus {
				border: 1px solid @color1;
				border-right: none;
				box-shadow: none;
				width: 180px;
			}

		}

		input::placeholder {
			color: #bbb;
		}

		.btn-search {
			padding: 3px 10px;
			border-radius: 0 30px 30px 0;
			border: 1px solid @color1;
			border-left: none;
			background: none;
			cursor: pointer;

			img {
				width: 18px;
			}
		}
	}
}

.breadcrumb {
	margin-bottom: 0;
	padding: 0;
	background: none;

	li {
		font-size: 11px;

		a {
			color: @color1;
		}

		&.active {
			color: #888;
		}

		&:before {
			color: #ddd !important;
		}
	}
}

.buttons-fixed {
	position: fixed;
	z-index: 222;
	padding: 10px;
	right: -30px;
	bottom: 5px;
	transition: all 0.3s;

	.fixed-background {
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		right: 0;
		height: 150%;
		width: 200%;
		border-radius: 20px 0 0 20px;
		background: rgba(255, 255, 255, 0.85);
		filter: blur(20px);
		opacity: 0;
		visibility: hidden;
		transition: all 0.3s;
	}

	> * {
		margin-bottom: 15px;

		&:last-child {
			margin-bottom: 0;
		}
	}

	.btn-circle {
		height: 40px;
		width: 40px;
		border-radius: 50%;
		display: flex;
		align-items: center;
		justify-content: center;
		border: none;
		outline: none;
		cursor: pointer;
		box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.5);
		transition: 0.3s;
		position: relative;

		span {
			position: absolute;
			right: 100%;
			padding-right: 10px;
			color: #444;
			font-size: 12px;
			transition: 0.3s;
			white-space: nowrap;
			visibility: hidden;
			opacity: 0;
		}

		img {
			height: 17px;
			opacity: 0;
			transition: 0.3s;
		}

		&:active, &:focus {
			span {
				visibility: hidden;
				opacity: 0;
			}
		}
	}

	.dropdown, .dropup {
		&.show {
			.btn-circle {
				border-radius: 2px 50% 50% 50%;
			}
		}

		.dropdown-menu {
			right: 100% !important;
			bottom: 100% !important;
			top: auto !important;
			left: auto !important;
			transform: none !important;

			.premium, .pro {
				position: relative;

				&:after {
					padding: 0px 5px;
					border-radius: 30px;
					position: absolute;
					top: -2px;
					right: 0;
					color: #fff;
					font-weight: 600;
					line-height: 12px;
					font-size: 8.5px;
					letter-spacing: -0.5px;
					text-transform: uppercase;
				}
			}

			.premium {
				&:after {
					content: 'Premium';
					background: #c31432;
				}
			}

			.pro {
				&:after {
					content: 'Pro';
					background: #78319d;
				}
			}
		}
	}

	&.body-bottom {
		bottom: 47px;
	}

	&.active {
		right: 0;

		.btn-circle {
			img {
				opacity: 1;
			}

			span {
				visibility: visible;
				opacity: 1;
			}
		}

		.fixed-background {
			opacity: 1;
			visibility: visible;
		}
	}
}

/* Content elements */

.alert {
	margin: 0;
	font-size: 14px;
	border-radius: 0;

	&.alert-absolute {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
	}
}

.nav-pills {
	margin-top: 10px;
	align-items: center;

	.nav-heading {
		margin-right: 20px;
	}

	.nav-item {
		margin-top: 10px;
		margin-right: 20px;

		a {
			background: #e7e7e7;
		}

		.premium, .pro {
			position: relative;
			background: #f3f3f3;
			box-shadow: inset 0px 0px 0px 1px #ccc;
			color: #777;

			&:after {
				padding: 0px 5px;
				border-radius: 30px;
				position: absolute;
				top: 0;
				left: 50%;
				color: #fff;
				font-weight: 600;
				line-height: 15px;
				font-size: 10px;
				letter-spacing: -0.5px;
				text-transform: uppercase;
				transform: translateX(-50%) translateY(-50%);
			}
		}

		.premium {
			&:after {
				content: 'Premium';
				background: #c31432;
			}
		}

		.pro {
			&:after {
				content: 'Pro';
				background: #78319d;
			}
		}
	}
}

.table-responsive {
	table {
		margin-bottom: 0;
		font-size: 11px !important; 

		tr {
			font-size: 12px;

			th {
				padding: 14px;
				font-size: 12px;
				font-weight: 500;

				a {
					color: #fff;
					opacity: 0.7;
					cursor: pointer;
				}

				&.active {
					a {
						font-weight: 700;
						position: relative;
						opacity: 1;

						&:after {
							content: '';
							height: 6px;
							width: 6px;
							top: 50%;
							transform: translateY(-50%);
							right: -10px;
							background: url('../img/icons/table/down-triangle.svg') center;
							background-size: cover;
							position: absolute;
						}
					}

					&.up {
						a {
							&:after {
								transform: translateY(-50%) rotate(180deg);
							}
						}
					}
				}
			}

			td {
				padding: 14px;
				vertical-align: middle;

				input {
					margin: 0;
					padding: 0 0 0 5px;
					height: 1.3rem;
					font-size: 12px;
					font-weight: 300;
				}

				.select2-selection--single, select {
					height: 1.3rem !important;
					font-size: 12px;
				}

				a, button {
					color: @color1;
					font-size: 12px;
					padding: 0;
					margin: 0;
					outline: none;
					background: none;
					border: none;
					display: flex;
					align-items: center;
					cursor: pointer;

					img {
						height: 15px;
					}
				}

				.checkbox-service {
					margin: 0;

					input {
						height: auto;
					}

					.service-inner {
						&:before {
							margin: 0;
						}
					}
				}

			}
			
			.d-flex {
				justify-content: flex-start;
				align-items: center;

				> * {
					margin-right: 20px;

					&:last-child {
						margin-right: 0;
					}
				}
			}

			&.alert-dot {
				background: #f9f1f1 !important;

				td {
					&:last-child {
						position: relative;

						&:before {
							content: '';
							height: 7px;
							width: 7px;
							position: absolute;
							border-radius: 50%;
							top: 50%;
							transform: translateY(-50%);
							background: #cd270c;
							right: 5px;
						}
					}
				}
			}

			&.success-dot {
				background: #f6f9f1 !important;

				td {
					&:last-child {
						position: relative;

						&:before {
							content: '';
							height: 7px;
							width: 7px;
							position: absolute;
							border-radius: 50%;
							top: 50%;
							transform: translateY(-50%);
							background: #74a517;
							right: 5px;
						}
					}
				}
			}

			&.warning-dot {
				background: #f9f9f1 !important;

				td {
					&:last-child {
						position: relative;

						&:before {
							content: '';
							height: 7px;
							width: 7px;
							position: absolute;
							border-radius: 50%;
							top: 50%;
							transform: translateY(-50%);
							background: #e1c402;
							right: 5px;
						}
					}
				}
			}

			&.info-dot {
				background: #e8edf7 !important;

				td {
					&:last-child {
						position: relative;

						&:before {
							content: '';
							height: 7px;
							width: 7px;
							position: absolute;
							border-radius: 50%;
							top: 50%;
							transform: translateY(-50%);
							background: #37a1ec;
							right: 5px;
						}
					}
				}
			}

			&:hover {
				background: #f9f9f9;
			}

			&:first-child {
				background: #777;
				color: #fff;
			}
		}
	}
}

.pagination-nav-wrapper {
	padding: 15px;
	background: #f9f9f9;
	margin-top: 20px;
	display: flex;
	align-items: center;
	justify-content: space-between;

	.pagination-view {
		display: flex;
		align-items: center;

		p {
			font-size: 12px;
			color: #777;
		}

		button, a {
			padding: 0;
			border: none;
			background: none;
			font-size: 12px;
			cursor: pointer;

			&.active {
				font-weight: 700;
				color: @color1;
			}
		}

		.form-service {
			margin-right: 5px;
		}

		input {
			width: 60px;
		}

		> * {
			margin-right: 10px;

			&:last-child {
				margin-right: 0;
			}
		}
	}

	.pagination-nav {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-wrap: wrap;

		li {
			a {
				display: block;
				padding: 10px;
				font-size: 13px;
				color: #666;
				transition: all 0.3s;

				&:hover {
					color: @color1;
				}
			}

			&.active {
				a {
					font-weight: 700;
					color: @color1;
				}
			}

			&:first-child, &:last-child {
				a {
					font-size: 12px;
				}
			}
		}
	}
}

.cards-grid {
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	grid-gap: 20px;

	.grid-service {
		display: block;
		background: #fff;
		border: 1px solid #e7e7e7;
		position: relative;

		.badge {
			position: absolute;
			top: 15px;
			left: 15px;
			height: 40px;
			width: 40px;
			border-radius: 50%;
			display: flex;
			align-items: center;
			justify-content: center;
			text-align: center;
			background: #cd270c;
			z-index: 111;
			font-size: 14px;
			font-weight: 400;
			color: #fff;
		}

		.service-image-wrapper {
			width: 100%;
			padding-top: 65%;
			position: relative;
			overflow: hidden;

			.service-image {
				top: 0;
				left: 0;
				position: absolute;
				width: 100%;
				height: 100%;
				display: flex;
				align-items: center;
			}
		}

		img {
			width: 100%;
		}

		.service-footer {
			padding: 15px;

			p {
				font-size: 16px;
				color: #444;
			}
		}

		&.premium, &.pro {
			position: relative;

			.service-image-wrapper {
				opacity: 0.4;
			}

			&:after {
				padding: 0px 12px;
				border-radius: 30px;
				position: absolute;
				top: 10px;
				right: 10px;
				color: #fff;
				font-weight: 600;
				font-size: 13px;
				text-transform: uppercase;
			}
		}

		&.premium {
			&:after {
				content: 'Premium';
				background: #c31432;
			}
		}

		&.pro {
			&:after {
				content: 'Pro';
				background: #78319d;
			}
		}
	}
}

.form-service {
	margin-top: 0 !important;

	label {
		margin-top: 20px;
		font-size: 12px;
		color: #777;
		font-weight: 400;
	}

	input, select {
		height: 35px;
		font-size: 14px;
		color: #444;
	}

	select {
		cursor: pointer;
	}

	.select2 {
		width: 100% !important;
	}

	.select2-selection--single {
		display: block;
		height: 35px !important;
		font-size: 14px;
		color: #444 !important;
		border: 1px solid #ced4da !important;
		border-radius: .25rem !important;
	}

	.select2-selection__rendered {
		height: 100%;
		display: flex !important;
		align-items: center;
	}

	.select2-selection__arrow {
		height: 100% !important;
	}

	.select2-search__field {
		font-size: 14px !important;
		color: #444 !important;
	}

	textarea {
		font-size: 14px;
		color: #444;
	}

	h1, h2, h3 {
		margin: 0 !important;
	}

	.info {
		margin-top: 20px;

		p {
			font-size: 11px;
			color: #777;
		}
	}

	.btn {
		margin-top: 20px;
	}
}

.checkbox-service {
	margin-top: 20px;
	margin-bottom: 0;
	cursor: pointer;
	width: 100%;
	display: block;

	.service-inner {
		display: flex;

		.name {
			font-weight: 400;
			text-align: left;
			font-size: 13px;
		}

		&:before {
			content: '';
			display: block;
			margin-top: 3px;
			margin-right: 12px;
			height: 12px;
			min-width: 12px;
			max-width: 12px;
			background: url('../img/icons/success.svg') center;
			transition: all 0.4s;
		}
	}

	input {
		visibility: hidden;
		position: absolute;
	}
}

.checkbox-service > input:checked + .service-inner {
	&:before {
		background: url('../img/icons/success-fill.svg') center;
	}
}

.dropdown, .dropup {
	.dropdown-toggle {
		&:after {
			content: none;
		}
	}

	.dropdown-menu {
		padding: 10px;
		border: 1px solid #e7e7e7;

		a, button {
			transition: all 0.5s;
			border-radius: 4px;
			font-size: 12px;
			cursor: pointer;

			&:hover {
				background: darken(@color1, 10%);
				color: #fff;
			}
		}

		&.dropdown-menu-right {
			a, button {
				text-align: right;
			}
		}
	}
}

.modal-service-wrapper {
	position: fixed;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	overflow-y: scroll;
	background: rgba(0, 0, 0, 0.9);
	z-index: 555;
	opacity: 0;
	visibility: hidden;
	transition: all 0.3s;

	&.active {
		opacity: 1;
		visibility: visible;
	}

	.modal-service {
		padding: 50px;
		position: relative;
		display: flex;
		justify-content: center;
		align-items: center;
		min-height: 100%;

		.service-inner {
			background: #fff;
			width: 500px;
			max-width: 100%;
			border: 1px solid #e7e7e7;
			padding: 20px;
			position: relative;

			.service-button {
				position: absolute;
				top: 12px;
				right: 9px;
				border: none;
				background: none;
				padding: 15px;
				display: flex;
				justify-content: center;
				align-items: center;
				cursor: pointer;
				outline: none;

				.button-bar {
					display: block;
					position: absolute;
					width: 20px;
					height: 2px;
					background: #222;

					&:nth-child(1) {
						transform: rotate(45deg);
					}

					&:nth-child(2) {
						transform: rotate(-45deg);
					}
				}
			}

			.service-content {
				.heading {
					margin-top: 0;
					margin-bottom: 10px;
					padding-right: 40px;
				}
			}

			.service-buttons {
				margin-top: 30px;
				display: flex;
				justify-content: space-between;
				align-items: center;
			}
		}
	}
}

.dm-uploader {
	border: 0.25rem dashed #e7e7e7;
}

.custom-file-label::after {
	content: "Przeglądaj";
}

.dm-uploader {
	cursor:default;
	-webkit-touch-callout:none;
	-webkit-user-select:none;
	-khtml-user-select:none;
	-moz-user-select:none;
	-ms-user-select:none;
	user-select:none;

	.btn {
		position:relative;
		overflow:hidden;
		
		input[type=file] {position:absolute;
			top:0;
			right:0;
			margin:0;
			border:solid transparent;
			width:100%;
			opacity:0;
			cursor:pointer;
		}
	}
}

.sticky-search-engine {
	position: sticky;
	top: 64px;
	z-index: 222;
	padding: 20px;
	background: #fff;
	border: 1px solid #e7e7e7;

	label {
		margin-top: 0;
	}

	.search-engine-inner {
		display: flex;
		align-items: center;

		.inner-service {
			width: 100%;
			display: flex;
			align-items: center;

			.form-service {
				margin-right: 13px;
			}

			.select2-container {
				margin-left: 13px;
				outline: none;

				.select2-selection--single {
					height: 38px !important;
				}
			}

			& + .inner-service {
				margin-left: 20px;
			}
		}
	}

	.btn {
		margin-left: 13px;
	}
}

/* Content elements */

section.section-footer {
	padding: 10px 0;
	transition: all .7s;
	position: relative;
	margin-top: -43px;
	border-top: 1px solid #e7e7e7;
	background: #fff;

	p {
		font-size: 0.7rem;
		font-weight: 300;
		color: #919191;
		text-align: right;
	}

	&.active {
		margin-left: @sidebar-width;
	}
}